import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TitelService {
  constructor(
    private translate: TranslateService,
    private title: Title,
    private activatedRoute: ActivatedRoute
  ) {}

  // Seitentitel aus route setzen, vergl.: https://stackoverflow.com/a/58863966
  public updateTitleFromRoute(): void {
    let titel = null;
    let child = this.activatedRoute.firstChild;
    while (child) {
      if (
        child.snapshot.url.find(s => s.path == 'workflows') ||
        (child.snapshot.url.find(s => s.path == 'vorlagen') &&
          (child.snapshot.url.find(s => s.path == 'edit') ||
            child.snapshot.url.find(s => s.path == 'new')))
      ) {
        // in der Anzeige der Workflows und der New- und Edit-Vorlagen Component wird der Titel seperat gesetzt,
        // da dieser abhängig vom WorkflowTitel bzw. VorlagenTyp ist.
        return;
      }
      titel = child.snapshot?.data['titel']
        ? (this.translate.instant(child.snapshot.data['titel'] as string | string[]) as string)
        : titel;

      child = child.firstChild;
    }

    this.setTitel(titel);
  }

  public setTitel(titel?: string): void {
    this.title.setTitle(
      (titel ? titel + ' - ' : '') +
        (this.translate.instant('Texte.Navigation.TenantName') as string)
    );
  }
}
